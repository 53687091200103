import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { TimeDifferencePipe } from './time-difference.pipe';
import { NL2BRPipe } from './nl2br.pipe';
import { LinkerPipe } from './linker.pipe';
import { MarkdownPipe } from './markdown.pipe';
import {DiscordTextPipe} from "./discordText.pipe";
import {WordsPipe} from "./words.pipe";


@NgModule({
  imports: [
    CommonModule,
    IonicModule
  ],
  declarations: [
    TimeDifferencePipe,
    NL2BRPipe,
    LinkerPipe,
    MarkdownPipe,
    DiscordTextPipe,
    WordsPipe
  ],
  exports: [
    TimeDifferencePipe,
    NL2BRPipe,
    LinkerPipe,
    MarkdownPipe,
    DiscordTextPipe,
    WordsPipe
  ]
})
export class PipesModule {}
