import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
import { marked } from 'marked';
import { sanitize } from 'dompurify';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'markdown' })

export class MarkdownPipe implements PipeTransform {
  transform(value: any): string {
    if (value == null) { return ''; }
    return sanitize(marked.parse(value));
  }
}
