import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'linker' })

export class LinkerPipe implements PipeTransform {
  transform(value: any): string {
    const match = value.match(/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig);
    let replacedURLs = value;

    if (!!match) {
      match.map(url => {
        replacedURLs = replacedURLs.replaceAll(url, '<a href="' + url + '" target="_blank">' + url + '</a>');
      });
    }
    return replacedURLs;
  }
}
