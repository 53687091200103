import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'words' })

export class WordsPipe implements PipeTransform {
  transform(value: string, start: number, end?: number): string {
    if (value == null) { return ''; }

    return value
        .split(' ')
        .splice(start, end)
        .join(' ') + '...';
  }
}
