import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'discordtext' })

export class DiscordTextPipe implements PipeTransform {
  transform(value: any): string {
    value = value.replaceAll('\n', '<br />'); //@todo sachen ersetzen
    return value;
  }
}
