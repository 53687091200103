import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nl2br' })

export class NL2BRPipe implements PipeTransform {
  transform(value: any): string {
    if (value == null) { return ''; }
    return value.replaceAll('\n', '<br />');
  }
}
